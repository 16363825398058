import { ReactComponent as IconClock } from '@assets/icons/fi-rr-clock.svg';
import { Group, Paper, SimpleGrid, Stack, Text, UnstyledButton } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  options: string[];
  selected?: string;
  setTime: (time: string) => void;
  durationInMinutes: number;
}

export const TimeSelector: FunctionComponent<IProps> = ({ options, selected, setTime, durationInMinutes }) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();
  return (
    <Stack gap={'sm'}>
      <Group justify="space-between">
        <Text fw={600} size={'lg'}>
          {t('selectTime')}
        </Text>
        {durationInMinutes ? (
          <Group align="center" gap="xs">
            <IconClock height={16} />
            <Text data-secondary fw={600} size={'md'}>
              {`${durationInMinutes}min`}
            </Text>
          </Group>
        ) : null}
      </Group>
      <SimpleGrid cols={width < 400 ? 3 : 4} spacing={8}>
        {options.map((opt) => {
          const isSelected = selected === opt;
          return (
            <UnstyledButton
              m="xs"
              key={opt}
              onClick={() => {
                setTime(opt);
              }}
              styles={{
                root: {
                  borderRadius: '8px',
                },
              }}
            >
              <Paper
                py={'xs'}
                px={'sm'}
                radius={'md'}
                role="button"
                shadow={isSelected ? 'sm' : 'xs'}
                data-selected={isSelected}
              >
                <Group justify="center">
                  <Text data-selectable-secondary fw={600}>
                    {opt}
                  </Text>
                </Group>
              </Paper>
            </UnstyledButton>
          );
        })}
      </SimpleGrid>
    </Stack>
  );
};
