import { Box, Group, Paper, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FunctionComponent } from 'react';

import classes from './css-modules/DescriptionCard.module.css';

interface IProps {
  image?: React.ReactNode;
  title: string;
  description: string;
}

export const DescriptionCard: FunctionComponent<IProps> = ({ image, title, description }) => {
  const { width } = useViewportSize();
  const small = width < 420;
  const imageNode = (
    <Box h={32} w={32}>
      {image}
    </Box>
  );
  return (
    <Paper classNames={{ root: classes.root }} p={'md'}>
      <Stack gap="sm">
        {small ? (
          <Stack gap="sm">
            {imageNode}
            <Text classNames={{ root: classes.title }} fw={600} lh={'20px'}>
              {title}
            </Text>
          </Stack>
        ) : (
          <Group gap="sm" wrap={'nowrap'}>
            {imageNode}
            <Text classNames={{ root: classes.title }} fw={600} lh={'20px'}>
              {title}
            </Text>
          </Group>
        )}

        <Text classNames={{ root: classes.text }} size="sm">
          {description}
        </Text>
      </Stack>
    </Paper>
  );
};
