import { ReactComponent as IconCarrot } from '@assets/icons/fi-rr-carrot.svg';
import { ReactComponent as IconChartSetTheory } from '@assets/icons/fi-rr-chart-set-theory.svg';
import { ReactComponent as IconComment } from '@assets/icons/fi-rr-comment.svg';
import { ReactComponent as IconCross } from '@assets/icons/fi-rr-cross.svg';
import { ReactComponent as IconHealth } from '@assets/icons/fi-rr-health.svg';
import { ReactComponent as IconMedicine } from '@assets/icons/fi-rr-medicine.svg';
import { ReactComponent as IconMobile } from '@assets/icons/fi-rr-mobile-notch.svg';
import { SimpleGrid, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { IconCard } from '@shared/components/IconCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { WeightLossSolutionsInput } from '@shared/gql/sdk';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export type WeightLossSolution = keyof WeightLossSolutionsInput;

type WeightLossSolutionsForm = Partial<Record<WeightLossSolution, boolean>>;

export const PastWeightLossSolutions: FunnelPageComponent<Partial<WeightLossSolutionsForm>, IFunnelContext> = ({
  data: weightLossSolutions,
  funnelApi,
}) => {
  const { t } = useTranslation();
  const initialValues = {
    NONE: weightLossSolutions?.NONE,
    DIETING: weightLossSolutions?.DIETING,
    COACHING: weightLossSolutions?.COACHING,
    PHYSICAL_TRAINING: weightLossSolutions?.PHYSICAL_TRAINING,
    MEDICATION: weightLossSolutions?.MEDICATION,
    MOBILE_APPS: weightLossSolutions?.MOBILE_APPS,
    ONLINE_PROGRAMS: weightLossSolutions?.ONLINE_PROGRAMS,
  };

  const form = useForm<WeightLossSolutionsForm>({
    initialValues,
  });

  return (
    <Stack>
      <StepTitle title={t('whatWeightLossSolutionsYouTriedInThePast')} />

      <Stack>
        <SimpleGrid cols={2}>
          <IconCard
            icon={<IconCarrot height={24} />}
            label={t('dieting')}
            {...form.getInputProps('DIETING', {
              type: 'checkbox',
            })}
            onChange={(val) => {
              form.getInputProps('DIETING', { type: 'checkbox' }).onChange(val);
              if (val) {
                form.setFieldValue('NONE', false);
              }
            }}
          />
          <IconCard
            icon={<IconComment height={24} />}
            label={t('coaching')}
            {...form.getInputProps('COACHING', {
              type: 'checkbox',
            })}
            onChange={(val) => {
              form.getInputProps('COACHING', { type: 'checkbox' }).onChange(val);
              if (val) {
                form.setFieldValue('NONE', false);
              }
            }}
          />
          <IconCard
            icon={<IconHealth height={24} />}
            label={t('physicalTraining')}
            {...form.getInputProps('PHYSICAL_TRAINING', {
              type: 'checkbox',
            })}
            onChange={(val) => {
              form.getInputProps('PHYSICAL_TRAINING', { type: 'checkbox' }).onChange(val);
              if (val) {
                form.setFieldValue('NONE', false);
              }
            }}
          />
          <IconCard
            icon={<IconMedicine height={24} />}
            label={t('medication')}
            {...form.getInputProps('MEDICATION', { type: 'checkbox' })}
            onChange={(val) => {
              form.getInputProps('MEDICATION', { type: 'checkbox' }).onChange(val);
              if (val) {
                form.setFieldValue('NONE', false);
              }
            }}
          />
          <IconCard
            icon={<IconMobile height={24} />}
            label={t('mobileApps')}
            {...form.getInputProps('MOBILE_APPS', {
              type: 'checkbox',
            })}
            onChange={(val) => {
              form.getInputProps('MOBILE_APPS', { type: 'checkbox' }).onChange(val);
              if (val) {
                form.setFieldValue('NONE', false);
              }
            }}
          />
          <IconCard
            icon={<IconChartSetTheory height={24} />}
            label={t('onlinePrograms')}
            {...form.getInputProps('ONLINE_PROGRAMS', {
              type: 'checkbox',
            })}
            onChange={(val) => {
              form.getInputProps('ONLINE_PROGRAMS', { type: 'checkbox' }).onChange(val);
              if (val) {
                form.setFieldValue('NONE', false);
              }
            }}
          />
        </SimpleGrid>
        <IconCard
          icon={<IconCross height={24} />}
          label={t('noneOfTheAbove')}
          {...form.getInputProps('NONE', { type: 'checkbox' })}
          onChange={(val) => {
            form.getInputProps('NONE', { type: 'checkbox' }).onChange(val);
            if (val) {
              const values: WeightLossSolutionsForm = {
                COACHING: false,
                DIETING: false,
                MEDICATION: false,
                MOBILE_APPS: false,
                ONLINE_PROGRAMS: false,
                PHYSICAL_TRAINING: false,
              };
              form.setValues(values);
            }
          }}
        />
      </Stack>

      <NextButton
        disabled={!Object.values(form.values).filter(Boolean).length}
        onClick={() => funnelApi.next(form.values)}
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
