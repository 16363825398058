import { Card, RadioProps, Text } from '@mantine/core';
import { FunctionComponent } from 'react';

import { EmblaRadio } from './EmblaRadio';

export const RadioCard: FunctionComponent<RadioProps & { select: () => void }> = ({ select, ...props }) => {
  return (
    <Card
      shadow="xs"
      data-checked={props.checked || undefined}
      onClick={() => {
        select();
      }}
    >
      <EmblaRadio
        lh={24}
        {...props}
        label={
          <Text fw={600} onClick={(event) => event.stopPropagation()}>
            {props.label}
          </Text>
        }
      />
    </Card>
  );
};
