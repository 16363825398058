import { Card, Flex, Stack, Text } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

import { EmblaCheckbox } from './EmblaCheckbox';

interface IProps {
  onChange: (value: boolean) => void;
  checked?: boolean;
  icon: ReactNode;
  label: string;
}

export const IconCard: FunctionComponent<IProps> = ({ onChange, checked, icon, label }) => {
  return (
    <Card
      shadow="xs"
      onClick={() => {
        onChange(!checked);
      }}
    >
      <Stack gap={'xs'} justify="center" align="center">
        <Flex h={28} style={{ justifyContent: 'center' }}>
          {checked ? <EmblaCheckbox checked /> : icon}
        </Flex>
        <Text fw={600} onClick={(e) => e.preventDefault()}>
          {label}
        </Text>
      </Stack>
    </Card>
  );
};
