import MounjaroLogo from '@assets/images/mounjaro-logo.png';
import WegovyLogo from '@assets/images/wegovy-logo.png';
import { Card, Divider, Group, Image, Stack, Text } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const Medication: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Stack gap={0}>
      <Text py="xs" data-secondary fw="bold">
        {t('medication')}
      </Text>
      <Card>
        <Group align="flex-end" gap="xl">
          <Image h={64} src={MounjaroLogo} />
          <Image h={46} src={WegovyLogo} />
        </Group>
        <Divider my="md" />
        <Text>{t('weKeepDosageLowToMinimiseCosts')}</Text>
      </Card>
    </Stack>
  );
};
