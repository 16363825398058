import { Radio, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { MedicationWantedAnswers } from '@shared/gql/sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const MedicationWanted: FunnelPageComponent<{ value?: MedicationWantedAnswers }, IFunnelContext> = ({
  data: { value },
  funnelApi,
}) => {
  const { t } = useTranslation();
  const [isWilling, setIsWilling] = useState(value);

  return (
    <Stack gap={'md'}>
      <StepTitle title={t('areYouWillingToTakeMedication')} />

      <Radio.Group value={isWilling}>
        <Stack gap="sm">
          <RadioCard
            checked={isWilling === MedicationWantedAnswers.Yes}
            value={MedicationWantedAnswers.Yes}
            label={t('yes')}
            select={() => setIsWilling(MedicationWantedAnswers.Yes)}
          />
          <RadioCard
            checked={isWilling === MedicationWantedAnswers.No}
            value={MedicationWantedAnswers.No}
            label={t('no')}
            select={() => setIsWilling(MedicationWantedAnswers.No)}
          />
          <RadioCard
            checked={isWilling === MedicationWantedAnswers.NotSure}
            value={MedicationWantedAnswers.NotSure}
            label={t('notSure')}
            select={() => setIsWilling(MedicationWantedAnswers.NotSure)}
          />
        </Stack>
      </Radio.Group>

      <NextButton disabled={isWilling == null} onClick={() => funnelApi.next({ value: isWilling })} />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
