import EmblaCoach from '@assets/images/embla-coach.png';
import EmblaTeam from '@assets/images/embla-team.png';
import { EmblaTheme } from '@joinembla/theme';
import { Card, Checkbox, Divider, Group, Image, Stack, Text, UnstyledButton } from '@mantine/core';
import { FinancialPrice } from '@shared/gql/sdk';
import { formatAmount, getProductNameByProduct } from '@shared/helpers';
import { useTheme } from '@shared/theme';
import { IconClipboardPlus, IconDeviceMobile, IconRoute, IconTruckDelivery, IconUsersGroup } from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/v3/FunnelContext';

interface Props {
  selectedProduct: ProductSelection;
  price: FinancialPrice;
  selected: boolean;
  onClick?: () => void;
  compact?: boolean;
}

interface BulletItem {
  icon: ReactNode;
  label: string;
}

const YourRecommendedMembership: React.FC<Props> = ({ selectedProduct, price, selected, onClick, compact }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const isEmblaTeam = selectedProduct === ProductSelection.MedicationAndCoaching;

  return (
    <UnstyledButton onClick={onClick}>
      <Stack gap={0}>
        <Text py="xs" fw="bold">
          {t('yourRecommendedMembership')}
        </Text>

        <Card>
          <Top isEmblaTeam={isEmblaTeam} price={price} selected={selected} />
          {!compact && (
            <>
              <Divider my="md" color="embla.1" />
              <BulletPoints items={getItems(isEmblaTeam, t, theme.other.theme)} />
            </>
          )}
        </Card>
      </Stack>
    </UnstyledButton>
  );
};

export { YourRecommendedMembership };

const Top: React.FC<{ isEmblaTeam: boolean; price: FinancialPrice; selected: boolean }> = ({
  isEmblaTeam,
  price,
  selected,
}) => {
  const { t } = useTranslation();

  return (
    <Group align="flex-start" wrap="nowrap">
      <Card
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        data-background={true}
        px={12}
        w={56}
        h={80}
      >
        <Image src={isEmblaTeam ? EmblaTeam : EmblaCoach} data-img-filter={true} />
      </Card>
      <Stack style={{ flexGrow: 1 }} gap={0}>
        <Group justify="space-between" wrap="nowrap" align="top">
          <Text fw={400} lh={'30px'} size={'20px'}>
            {getProductNameByProduct(price.product)}
          </Text>
          <Checkbox checked={selected} readOnly />
        </Group>
        <Text fw={600}>{isEmblaTeam ? t('medicationSupportedPlan') : t('comprehensiveCoachingPlan')}</Text>
        {price?.campaignAmount ? (
          <Group>
            <Text td="line-through">
              {formatAmount(price.amount, price.currency)}/{t('month')}
            </Text>
            <Text>
              {formatAmount(price.campaignAmount, price.currency)}
              {price.campaignText ? `/${price.campaignText}` : `/${t('month')}`}
            </Text>
          </Group>
        ) : (
          <Text>
            {formatAmount(price.amount, price.currency)}/{t('month')}
          </Text>
        )}
      </Stack>
    </Group>
  );
};

const BulletPoints: React.FC<{
  items: BulletItem[];
}> = ({ items = [] }) => {
  const { t } = useTranslation();
  return (
    <Stack gap="sm">
      {items.map(({ icon, label }) => (
        <Group style={{ flexWrap: 'initial' }} key={label} gap="sm" align="center">
          <Card
            style={{
              padding: 8,
              borderRadius: 50,
            }}
            data-background-light
          >
            {icon}
          </Card>
          <Text fw={600}>{t(label)}</Text>
        </Group>
      ))}
    </Stack>
  );
};

const getItems = (isEmblaTeam: boolean, t: (key: string) => string, theme: EmblaTheme): BulletItem[] => {
  return isEmblaTeam
    ? [
        {
          label: t('coachPsychologistAndDoctor'),
          icon: <IconUsersGroup color={theme.funnel.icon.primary} size={20} />,
        },
        {
          label: t('1to1HealthGuidance'),
          icon: <IconRoute color={theme.funnel.icon.primary} size={20} />,
        },
        {
          label: t('fullAccessToEmblaApp'),
          icon: <IconDeviceMobile color={theme.funnel.icon.primary} size={20} />,
        },
        {
          label: t('monthlyFreeMedicationShipments'),
          icon: <IconTruckDelivery color={theme.funnel.icon.primary} size={20} />,
        },
      ]
    : [
        {
          label: t('dedicatedHealthCoach'),
          icon: <IconUsersGroup color={theme.funnel.icon.primary} size={20} />,
        },
        {
          label: t('1to1NutrationAndWellBeingGuidance'),
          icon: <IconRoute color={theme.funnel.icon.primary} size={20} />,
        },
        {
          label: t('personalisedActionPlanForTrackingProgress'),
          icon: <IconClipboardPlus color={theme.funnel.icon.primary} size={20} />,
        },
        {
          label: t('fullAccessToEmblaApp'),
          icon: <IconDeviceMobile color={theme.funnel.icon.primary} size={20} />,
        },
      ];
};
