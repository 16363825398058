import { Card, Divider, Group, Stack, Text, TextProps } from '@mantine/core';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  items: { label: string | ReactNode; value: string | ReactNode }[];
  total: string | ReactNode;
}
export const Receipt: React.FC<Props> = ({ items, total }) => {
  const { t } = useTranslation();
  const renderText = (item: string | ReactNode, textProps?: TextProps) => {
    if (typeof item === 'string') return <Text {...textProps}>{item}</Text>;
    return item;
  };
  return (
    <Card shadow="xs">
      <Stack>
        {items.map((item) => (
          <Group justify="space-between">
            {typeof item.label === 'string' ? <Text>{item.label}</Text> : item.label}
            {renderText(item.value)}
          </Group>
        ))}
      </Stack>
      <Divider my="md" color="embla.1" />
      <Group justify="space-between">
        <Text fw="bold">{t('total')}</Text>
        {renderText(total, { fw: 'bolder' })}
      </Group>
    </Card>
  );
};
