import { Radio, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { CountryIso3166, HeardAboutEmbla } from '@shared/gql/sdk';
import { resolveIntl } from '@shared/helpers';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../../FunnelContext';

const heardAbout: Partial<Record<CountryIso3166, HeardAboutEmbla[]>> = {
  [CountryIso3166.Dk]: [
    HeardAboutEmbla.Emails,
    HeardAboutEmbla.Facebook,
    HeardAboutEmbla.FriendReferal,
    HeardAboutEmbla.GoMorgenDanmark,
    HeardAboutEmbla.Google,
    HeardAboutEmbla.Instagram,
    HeardAboutEmbla.NewsArticle,
    HeardAboutEmbla.TvAdvert,
    HeardAboutEmbla.Youtube,
    HeardAboutEmbla.Pharmacy,
    HeardAboutEmbla.NotSure,
  ],
  [CountryIso3166.Gb]: [
    HeardAboutEmbla.Emails,
    HeardAboutEmbla.Facebook,
    HeardAboutEmbla.FriendReferal,
    HeardAboutEmbla.Google,
    HeardAboutEmbla.Instagram,
    HeardAboutEmbla.NewsArticle,
    HeardAboutEmbla.TvAdvert,
    HeardAboutEmbla.Youtube,
    HeardAboutEmbla.NotSure,
  ],
};
export const HeardAbout: FunnelPageComponent<
  {
    value?: HeardAboutEmbla;
    keysOrdered?: HeardAboutEmbla[];
  },
  IFunnelContext
> = ({ data: { value: initialHeardAboutValue, keysOrdered }, funnelApi }) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<HeardAboutEmbla | undefined>(initialHeardAboutValue);

  const heardAboutTranslations: Partial<Record<HeardAboutEmbla, string>> = {
    EMAILS: t('emails'),
    FACEBOOK: t('facebook'),
    FRIEND_REFERAL: t('aFriendReferredMe'),
    GOOGLE: t('google'),
    INSTAGRAM: t('instagram'),
    NOT_SURE: t('iAmNotSure'),
    TV_ADVERT: t('tvAdvert'),
    YOUTUBE: t('youtube'),
    NEWS_ARTICLE: t('newsArticle'),
    GO_MORGEN_DANMARK: t('goMorgenDanmark'),
    PHARMACY: t('pharmacy'),
  };

  const randomizeOrder = <T,>(array: T[]): T[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const displayKeys = useMemo(() => {
    const keys = keysOrdered || randomizeOrder(Object.keys(heardAboutTranslations) as HeardAboutEmbla[]);
    const country = resolveIntl().country;

    return keys.filter((key) => {
      const avbKeys = heardAbout[country];
      return avbKeys.includes(key);
    });
  }, [keysOrdered]);

  return (
    <Stack>
      <StepTitle title={t('beforeWeContinueWeWouldLoveToKnow')} mb={'xs'} />

      <Radio.Group value={value}>
        <Stack gap="sm">
          {displayKeys.map((heardAboutKey) => {
            return (
              <RadioCard
                key={heardAboutKey}
                label={heardAboutTranslations[heardAboutKey]}
                checked={value === heardAboutKey}
                value={heardAboutKey}
                select={() => {
                  setValue(heardAboutKey);
                }}
              />
            );
          })}
        </Stack>
      </Radio.Group>

      <NextButton
        disabled={!value}
        onClick={() =>
          funnelApi.next({
            value,
            keysOrdered: displayKeys,
          })
        }
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
