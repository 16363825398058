import { List, Stack, Text, TextProps, Title, TitleProps } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { TrustBox } from '@shared/components/TrustBox';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { ReactNode } from 'react';
import IFunnelContext from 'src/v3/FunnelContext';

type UtilityPageBaseOptions = { content?: ReactNode; nextLabel?: string; showTrustpilot?: boolean };
type UtilityPageOptions<T> = UtilityPageBaseOptions & T;

type TitleTextSection = { title: ReactNode; text?: ReactNode; titleProps?: TitleProps; textProps?: TextProps };

const createTitleTextSectionsPage =
  (options: UtilityPageOptions<{ sections: TitleTextSection[] }>): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <Stack>
        <Stack my="xl">
          {options.sections.map(({ title, titleProps, text, textProps }) => (
            <Stack gap={0}>
              <Title {...titleProps}>{title}</Title>
              <Text data-secondary-font {...textProps}>
                {text}
              </Text>
            </Stack>
          ))}
        </Stack>
        {options.content}
        {options.showTrustpilot && <TrustBox />}
        <NextButton grow label={options.nextLabel} onClick={funnelApi.next} />
        {funnelApi.state.hasPreviousPage && <BackButton grow onClick={funnelApi.back} />}
      </Stack>
    );
  };

const createTitleTextPage =
  (options: UtilityPageOptions<TitleTextSection>): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <Stack>
        <Title {...options.titleProps}>{options.title}</Title>
        <Text {...options.textProps} data-secondary-font size="lg">
          {options.text}
        </Text>
        {options.content}
        {options.showTrustpilot && <TrustBox />}
        <NextButton grow label={options.nextLabel} onClick={funnelApi.next} />
        {funnelApi.state.hasPreviousPage && <BackButton grow onClick={funnelApi.back} />}
      </Stack>
    );
  };

const createTitleBulletPointsPage =
  (
    options: UtilityPageOptions<{ title: ReactNode; titleProps?: TitleProps; bulletpoints: ReactNode[] }>,
  ): FunnelPageComponent<undefined, IFunnelContext> =>
  ({ funnelApi }) => {
    return (
      <Stack>
        <Title {...options.titleProps}>{options.title}</Title>
        <List withPadding style={{ listStylePosition: 'outside' }} spacing={8}>
          {options.bulletpoints.map((b) => (
            <List.Item key={String(b)}>
              <Text data-secondary-font size="lg">
                {b}
              </Text>
            </List.Item>
          ))}
        </List>
        {options.content}
        {options.showTrustpilot && <TrustBox />}
        <NextButton grow label={options.nextLabel} onClick={funnelApi.next} />
        {funnelApi.state.hasPreviousPage && <BackButton grow onClick={funnelApi.back} />}
      </Stack>
    );
  };

export const PageTemplates = {
  TitleText: createTitleTextPage,
  TitleBulletPoints: createTitleBulletPointsPage,
  TitleTextSections: createTitleTextSectionsPage,
};
