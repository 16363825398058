import Sketch1 from '@assets/images/sketch_1.svg';
import Sketch2 from '@assets/images/sketch_2.svg';
import { Box, Card, Divider, Group, Image, ImageProps, Stack, Text, Title } from '@mantine/core';
import { useTheme } from '@shared/theme';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { t } from 'src/i18n';

import { PageTemplates } from '../pageTemplates';

const InfoCard: FunctionComponent<{
  bgColor: string;
  title: string;
  description: string;
  imageSrc: ImageProps['src'];
}> = ({ bgColor, title, description, imageSrc }) => {
  return (
    <Card flex={1} p="md" bg={bgColor} withBorder>
      <Stack gap="sm">
        <Box h={50}>
          <Image fit="contain" h={50} src={imageSrc} />
        </Box>
        <Title size="h4" ta="center">
          {title}
        </Title>
        <Divider h={1} color="black" opacity={0.1} />
        <Text ta="center" data-secondary-font>
          {description}
        </Text>
      </Stack>
    </Card>
  );
};
const Content: FunctionComponent = () => {
  const { theme } = useTheme();
  const emblaTheme = theme.other.theme;
  const { t } = useTranslation();

  return (
    <Group align="stretch">
      <InfoCard
        imageSrc={Sketch2}
        title={t('medicalWeightLoss.title')}
        description={t('medicalWeightLoss.text')}
        bgColor={emblaTheme.screen.brand}
      />
      <InfoCard
        imageSrc={Sketch1}
        title={t('coaching.title')}
        description={t('coaching.text')}
        bgColor={emblaTheme.rainbow.background.v5}
      />
    </Group>
  );
};

export const WhoCanWeHelp = PageTemplates.TitleText({
  title: t('whoCanWeHelp.title'),
  text: t('whoCanWeHelp.text'),
  content: <Content />,
});
