import api, { isAuthenticated } from '@shared/api';
import { FunnelEngineData } from '@shared/funnel-engine';
import { UnitSystem } from '@shared/gql/sdk';
import { env } from 'src/env';
import IFunnelContext from 'src/v3/FunnelContext';
import { getCreateMeasurementInputs } from 'src/v3/util/measurementHelper';

import { createPartnerFunnelConfig } from '../config';

type Data = FunnelEngineData<ReturnType<typeof createPartnerFunnelConfig>['config']>;

export const saveData = async (data: Data, context: IFunnelContext, terminal = false) => {
  if (!isAuthenticated()) return;

  try {
    const { registration } = data;
    await api.SignupFunnelFinalizeV3({
      data: {
        measurements: getCreateMeasurementInputs(data.registration.height_weight),
        partnerClientSessionId: context.partnerClientSession?.id,
        glp1: registration.glp1.value,
        comorbidity: registration.comorbidity,
        eligibility: registration.eligibility,
        height_weight: {
          height: data.registration.height_weight.heightCm,
          weight: data.registration.height_weight.weightKg,
          unitSystem: UnitSystem.Metric,
        },
        emblaShareCode: env.partnerCoupon,
        notEligibleReason: context.notEligibleReason,
        heardAboutEmbla: registration.heard_about.value,
      },
      terminal,
    });
  } catch (e) {
    console.warn('Failed to save data');
  }
};
