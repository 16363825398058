import { ReactComponent as CalendarIcon } from '@assets/icons/fi-rr-calendar-2.svg';
import { ReactComponent as CheckmarkIcon } from '@assets/icons/fi-rr-check.svg';
import { ReactComponent as ClockIcon } from '@assets/icons/fi-rr-clock.svg';
import { ReactComponent as MobileIcon } from '@assets/icons/fi-rr-mobile-notch.svg';
import { ReactComponent as UserIcon } from '@assets/icons/fi-rr-users.svg';
import { Button, Stack, Text } from '@mantine/core';
import { AddToCalendarButton } from '@shared/components/buttons/AddToCalendarButton';
import { AppButton } from '@shared/components/buttons/AppButton';
import { KeyValueInfoCard } from '@shared/components/KeyValueInfoCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { getRootPath } from '@shared/helpers';
import dayjs from 'dayjs';

import { env } from '../../../src/env';
import { t } from '../../i18n';
import IFunnelContext from '../FunnelContext';

export const BookingSummary: FunnelPageComponent<undefined, IFunnelContext> = ({ funnelApi, context }) => {
  const { serverRoot } = env || { serverRoot: 'https://app-joinembla.com' };
  const { selectedTimeslot, appointmentId } = context;
  const { embedded, partnerClientSession } = context;

  let topText = t('oneLastThing');
  let title = t('downloadAppToParticipateInConsultation');
  if (embedded) {
    topText = t('bookingConfirmed');
    title = t('youWillBeAttendingConsulationViaApp');
  }

  const date = dayjs(selectedTimeslot?.startTime).format('LL');
  const startTime = dayjs(selectedTimeslot?.startTime).format('HH:mm');
  const endTime = dayjs(selectedTimeslot?.endTime).format('HH:mm');
  return (
    <Stack justify="space-between">
      <Stack gap="lg" py="md">
        <Text ta="center" fw={700}>
          {topText}
        </Text>
        <StepTitle mt={0} mb={0} title={title} />

        <KeyValueInfoCard
          title={t('yourBookingConfirmation')}
          config={[
            {
              icon: <UserIcon height={18} />,
              label: t(selectedTimeslot.userRole.toLowerCase()),
              value: selectedTimeslot.name,
            },
            {
              icon: <CalendarIcon height={18} />,
              label: t('date'),
              value: date,
            },
            {
              icon: <ClockIcon height={18} />,
              label: t('time'),
              value: `${startTime} - ${endTime}`,
            },
            {
              icon: <MobileIcon height={18} />,
              label: t('how'),
              value: t('videoConsultation'),
            },
          ]}
          footer={
            <AddToCalendarButton
              variant={'light'}
              isEmbeddedInApp={embedded}
              serverRoot={serverRoot}
              appointmentId={appointmentId}
              iconPosition="left"
              size="sm"
            />
          }
        />
      </Stack>
      {embedded || !!partnerClientSession ? (
        <Button
          leftSection={<CheckmarkIcon height={20} />}
          variant="transparent"
          onClick={() => {
            if (embedded) {
              // on this url the app knows that it's time to close webview and refetch user data
              window.location.href = `${getRootPath()}/app-complete`;
            } else {
              funnelApi.next(undefined);
            }
          }}
        >
          {t('finish')}
        </Button>
      ) : (
        <AppButton />
      )}
    </Stack>
  );
};
