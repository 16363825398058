import SlideSketch from '@assets/images/slide_sketch.svg';
import { Box, Button, Group, Image, Input, Stack, Text, Title } from '@mantine/core';
import { EmblaPageLayout } from '@shared/components/layout/PageLayout';
import { PartnerInfo } from '@shared/gql/sdk';
import { IconArrowRight } from '@tabler/icons-react';
import { FunctionComponent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePartnerSession } from './util/helpers';

const PartnerWelcome: FunctionComponent<{ partnerInfo: PartnerInfo }> = ({ partnerInfo }) => {
  const { t } = useTranslation();

  return (
    <EmblaPageLayout heroImageSrc={SlideSketch}>
      <Stack gap="xl">
        <Group wrap="nowrap">
          <Image src={partnerInfo.logoImageUrl} fit="contain" mah={150} />
          <Box flex={1} />
        </Group>
        <Title fw={400}>
          <Trans components={{ strong: <Title style={{ display: 'inline' }} /> }} i18nKey="partner.entry.title" />
        </Title>
        <Stack>
          <Button component="a" href="/dk/partner/signup">
            {t('btn.partner.signup')}
          </Button>
          <Button component="a" href="/dk/partner/checkup" variant="outline">
            {t('btn.partner.checkup')}
          </Button>
        </Stack>
      </Stack>
    </EmblaPageLayout>
  );
};

const PartnerEnterCode: FunctionComponent<{ invalidCode?: boolean }> = ({ invalidCode }) => {
  const { t } = useTranslation();
  const [codeInput, setCodeInput] = useState<string>();
  const { setPartnerCode } = usePartnerSession(true);

  return (
    <EmblaPageLayout heroImageSrc={SlideSketch}>
      <Stack gap="lg">
        <Stack gap="xs">
          <Title>{t('partner.welcome')}</Title>
          <Text data-secondary-font>{t('partner.entry.enterCode')}</Text>
        </Stack>
        <Input.Wrapper label={t('partnerId')} error={invalidCode ? t('invalidCode') : undefined}>
          <Input placeholder={t('idNumber')} onChange={(e) => setCodeInput(e.currentTarget.value)} maxLength={8} />
        </Input.Wrapper>
        <Button
          rightSection={<IconArrowRight />}
          disabled={codeInput?.length !== 8}
          onClick={() => setPartnerCode(codeInput)}
        >
          {t('next')}
        </Button>
      </Stack>
    </EmblaPageLayout>
  );
};

export const PartnerEntry: FunctionComponent = () => {
  const [invalidCode] = useState(false);
  const { partnerCode, partnerInfo } = usePartnerSession(true);

  const loading = partnerCode && !partnerInfo;
  const ready = partnerCode && partnerInfo;

  if (loading) return null;
  if (ready) return <PartnerWelcome partnerInfo={partnerInfo} />;
  else return <PartnerEnterCode invalidCode={invalidCode} />;
};
