import { Box, Group, MantineSize, Text } from '@mantine/core';
import { FunctionComponent } from 'react';

interface IProps {
  label: string;
  size?: MantineSize;
}
export const LeftLabel: FunctionComponent<React.PropsWithChildren<IProps>> = ({ children, label, size }) => {
  return (
    <Group justify="space-between" align="center">
      <Text size={size} fw={600}>
        {label}
      </Text>
      <Box>{children}</Box>
    </Group>
  );
};
