import { Stack, Text } from '@mantine/core';
import { IFunnelLoaderConfig } from '@shared/funnel-engine';
import { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';

import { ProductSelection } from '../FunnelContext';

export const RecommendationLoader: FunctionComponent<{
  showMedicalInformation: () => boolean;
}> = ({ showMedicalInformation }) => {
  const shouldShowMedicalInformation = showMedicalInformation();

  return (
    <Stack justify="center" align="center">
      <Stack gap={'xl'}>
        <Text ta="center" size={'xl'}>
          <Trans i18nKey={'wereGeneratingYourRecommendationNow'} />
        </Text>
        {shouldShowMedicalInformation ? (
          <Text ta="center" size={'xl'}>
            <Trans i18nKey={'aClinicianWillAssessYourMedicalInformationOverACAll'} />
          </Text>
        ) : null}

        <Text ta="center" size={'sm'}>
          <Trans i18nKey={'youDontHaveToDoAnythingAtThisPoing'} />
        </Text>
      </Stack>
    </Stack>
  );
};

export const resolveRecommendationLoader = (selectedProduct: ProductSelection): IFunnelLoaderConfig => ({
  component: RecommendationLoader.bind(null, {
    showMedicalInformation: () => selectedProduct === ProductSelection.MedicationAndCoaching,
  }),
  minimumDurationSeconds: 8,
  offsetY: -100,
});
