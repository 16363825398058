import { FunnelEngineData, FunnelRoutes } from '@shared/funnel-engine';
import { HeardAboutEmbla, NonEligibleReason, TrackingEventName } from '@shared/gql/sdk';
import { trackingService } from '@shared/TrackingContext';

import IFunnelContext, { ProductSelection } from '../../../FunnelContext';
import { getSessionLock } from '../../../util/helpers';
import { createFunnelV3DKConfig } from '../config';

type Pages = FunnelRoutes<ReturnType<typeof createFunnelV3DKConfig>['config']>;

const { trackEvent, pushGTMEvent } = trackingService;

const mapEligibleToTrackingProduct = (eligibleFor: ProductSelection) => {
  switch (eligibleFor) {
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      return 'COACHING_AND_MEDICATION';
    case ProductSelection.CoachingOnly:
      return 'COACHING';
  }
};

const mapEligibleToTrackingEligibility = (eligibleFor: ProductSelection) => {
  switch (eligibleFor) {
    case ProductSelection.MedicationAndCoaching:
      return 'MedicineAndCoaching';
    case ProductSelection.CoachingAndMaybeMedication:
      return 'CoachingAndMaybeMedicine';
    case ProductSelection.CoachingOnly:
      return 'CoachingOnly';
    default:
      return 'NotEligible';
  }
};

export const onPageEntry = (page: Pages, { eligibleFor }: IFunnelContext) => {
  pushGTMEvent('pageview', { funnel_page: page });

  switch (page) {
    case 'eligibility/registration':
      if (getSessionLock('signupStarted')) {
        trackEvent(TrackingEventName.SignupStarted);
      }
      break;
    case 'booking/confirm_phone':
      trackEvent(TrackingEventName.SignupBookingcodeAsked);
      break;
    case 'booking/method':
    case 'eligibility/tapering_off':
      trackEvent(TrackingEventName.SignupOfferExplained, {
        product: mapEligibleToTrackingProduct(eligibleFor),
      });
      break;
    case 'booking/pick_timeslot':
      trackEvent(TrackingEventName.SignupTimeslotPresented);
      break;
    case 'booking/prediction':
      trackEvent(TrackingEventName.SignupWeightlossPredicted, {
        prediction_result: mapEligibleToTrackingEligibility(eligibleFor),
      });
      break;
  }
};

export const onPageExit = (
  page: Pages,
  context: IFunnelContext,
  data: FunnelEngineData<ReturnType<typeof createFunnelV3DKConfig>['config']>,
) => {
  switch (page) {
    case 'eligibility/registration':
      if (context.userSession?.userId) {
        trackEvent(TrackingEventName.SignupRegistered);
        pushGTMEvent('user_registered', {
          userId: context.userSession.userId,
        });
      }
      break;
    case 'eligibility/heard_about':
      {
        const heardAboutMap: Partial<Record<HeardAboutEmbla, string>> = {
          [HeardAboutEmbla.Emails]: 'emails',
          [HeardAboutEmbla.Facebook]: 'facebook',
          [HeardAboutEmbla.FriendReferal]: 'aFriendReferredMe',
          [HeardAboutEmbla.Google]: 'google',
          [HeardAboutEmbla.Instagram]: 'instagram',
          [HeardAboutEmbla.NotSure]: 'iAmNotSure',
          [HeardAboutEmbla.GoMorgenDanmark]: 'goMorgenDanmark',
          [HeardAboutEmbla.TvAdvert]: 'tvAdvert',
          [HeardAboutEmbla.Youtube]: 'youtube',
          [HeardAboutEmbla.NewsArticle]: 'newsArticle',
          [HeardAboutEmbla.Pharmacy]: 'pharmacy',
        };

        const heardAboutMappedToString = heardAboutMap[data.eligibility.heard_about.value];

        trackEvent(TrackingEventName.SignupHeardAboutAnswered, {
          answer: heardAboutMappedToString,
        });
      }
      break;
    case 'eligibility/glp1':
      trackEvent(TrackingEventName.SignupGlp1Answered, {
        GLP1_at_signup: context.onGLP1Medication,
      });
      break;
    case 'booking/confirm_phone':
      trackEvent(TrackingEventName.SignupConsultationConfirmed, {
        bookedAppointmentId: context.appointmentId,
      });
      pushGTMEvent('screening_booked', {
        bookedAppointmentId: context.appointmentId,
      });
      break;
    case 'eligibility/comorbidity':
      trackEvent(TrackingEventName.SignupComorbidityQuestionsAnswered);
      break;
    case 'eligibility/eligibility':
      {
        trackEvent(TrackingEventName.SignupEligibilityQuestionsAnswered);
        const reasons = [];
        if (context.notEligibleReason === 'addiction') {
          reasons.push('AlcoholDrugs');
        }
        if (context.notEligibleReason === 'eating-disorder') {
          reasons.push('EatingDisorder');
        }
        trackEvent(TrackingEventName.SignupScreeningCompleted, {
          bmi: context.measurements.bmi,
          eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
          reasons,
        });
      }
      break;
    case 'eligibility/height_weight':
      trackEvent(TrackingEventName.SignupHeightWeightEntered, {
        bmi: context.measurements.bmi,
        weight: context.measurements.weight,
        unitSystem: context.measurements.preferredUnitSystem,
      });
      trackEvent(TrackingEventName.SignupUnitSystemPicked, {
        unit_system: context.measurements.preferredUnitSystem,
      });
      if (context.measurements.bmi < 27) {
        trackEvent(TrackingEventName.SignupScreeningCompleted, {
          bmi: context.measurements.bmi,
          eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
          reasons: NonEligibleReason.BmiTooLow,
        });
      }
      break;
    case 'booking/method':
      trackEvent(TrackingEventName.SignupOfferSelected, {
        product_selected: mapEligibleToTrackingProduct(context.eligibleFor),
        eligibility_result: mapEligibleToTrackingEligibility(context.eligibleFor),
        bmi: context.measurements.bmi,
      });
      break;
    case 'booking/pick_timeslot':
      trackEvent(TrackingEventName.SignupTimeslotPicked, {
        calendar_id: context.selectedTimeslot.calendarId,
        start_time: context.selectedTimeslot.startTime,
      });
      break;
  }
};
