import { ReactComponent as IconInfo } from '@assets/icons/fi-rr-info.svg';
import { Alert, Badge, Button, Group, Paper, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FunctionComponent, MouseEventHandler } from 'react';

import classes from './css-modules/Disclaimer.module.css';

interface IProps {
  type: 'info' | 'warning' | 'error';
  pillText: string;
  message: string;
  action?: { label: string; onClick: MouseEventHandler<HTMLButtonElement> };
}

export const Disclaimer: FunctionComponent<IProps> = ({ type, pillText, message, action }) => {
  const { width } = useViewportSize();

  return (
    <Paper shadow="xs" style={{ width: '100%' }}>
      <Alert
        variant="filled"
        styles={{
          body: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          },
        }}
        data-type={type}
        classNames={{ root: classes.root }}
        title={
          <Badge
            p="md"
            fw={600}
            fz={16}
            tt="none"
            radius={100}
            variant="filled"
            data-type={type}
            classNames={{ root: classes.badge }}
          >
            <Group align="center" gap="sm">
              <IconInfo data-type={type} width={16} className={classes.badgeText} />
              <Text data-type={type} classNames={{ root: classes.badgeText }}>
                {pillText}
              </Text>
            </Group>
          </Badge>
        }
      >
        <Stack justify="center">
          <Text ta="center" data-type={type} classNames={{ root: classes.text }}>
            {message}
          </Text>
          {action ? (
            <Group grow={width < 500} justify="center">
              <Button
                mb={'xs'}
                size={'md'}
                miw={222}
                data-type={type}
                classNames={{ root: classes.actionButton }}
                onClick={action.onClick}
                variant="outline"
              >
                {action.label}
              </Button>
            </Group>
          ) : null}
        </Stack>
      </Alert>
    </Paper>
  );
};
