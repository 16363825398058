import { Group, Progress, Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FunctionComponent } from 'react';

export const TopBar: FunctionComponent<{
  sections: { label: string; percentComplete: number }[];
}> = ({ sections }) => {
  const { width } = useViewportSize();

  return (
    <Stack w="100%">
      <Group grow gap={width < 500 ? 'xs' : 'lg'}>
        {sections.map((item, index) => (
          <TopBarItem key={`${item.label}-${index}`} {...item} />
        ))}
      </Group>
    </Stack>
  );
};

interface ITopBarItem {
  label: string;
  percentComplete: number;
}

const TopBarItem = ({ label, percentComplete }: ITopBarItem) => {
  return (
    <Stack gap={'xs'}>
      <Progress value={percentComplete} size={4} />
      <Text ta="left" size={'md'}>
        {label}
      </Text>
    </Stack>
  );
};
