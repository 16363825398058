import api from '@shared/api';
import { funnelConfiguration, funnelPage } from '@shared/funnel-engine';
import { PartnerClientSessionType } from '@shared/gql/sdk';
import { t } from 'src/i18n';
import IFunnelContext from 'src/v3/FunnelContext';
import { PageTemplates } from 'src/v3/pages/pageTemplates';
import { EnterCode, EnterCodeErrors } from 'src/v3/pages/PartnerCheckup/EnterCode';
import { createEnterMeasurementsPage } from 'src/v3/pages/PartnerCheckup/EnterMeasurements';
import { MeasurementIntro } from 'src/v3/pages/PartnerCheckup/MeasurementIntro';
import { MeasurementStats } from 'src/v3/pages/PartnerCheckup/MeasurementStats';
import { Welcome } from 'src/v3/pages/PartnerCheckup/Welcome';
import { saveMeasurements } from 'src/v3/util/measurementHelper';

export const createPartnerCheckupFunnelConfig = (initialContext: Partial<IFunnelContext>) =>
  funnelConfiguration({
    config: {
      checkup: {
        enter_code: funnelPage(EnterCode, {
          noContainer: true,
          noSectionDisplay: true,
          onExit: async ({ data, context, funnelApi }) => {
            try {
              const { CreatePartnerClientSession: partnerClientSession } = await api.CreatePartnerClientSession({
                type: PartnerClientSessionType.Checkup,
                partnerCode: context.partnerCode,
                clientPartnerAccessCode: data.code,
              });
              funnelApi.updateContext({ partnerClientSession, clientPartnerAccessCode: data.code });
            } catch (e) {
              return EnterCodeErrors.InvalidCode;
            }
          },
        }),
        welcome: funnelPage(Welcome, {
          onExit: async ({ data }) => {
            if (!data.continue) {
              window.location.href = '/dk/partner';
            }
          },
        }),
        measurement_intro: funnelPage(MeasurementIntro),
        enter_measurements: funnelPage(createEnterMeasurementsPage(), {
          pageWidth: 720,
          onExit: async ({ data, context, funnelApi }) => {
            const addedMeasurements = await saveMeasurements(data, context.clientPartnerAccessCode);

            if (addedMeasurements.length > 0) {
              funnelApi.updateContext({
                partnerClientSession: {
                  ...context.partnerClientSession,
                  clientInfo: {
                    ...context.partnerClientSession.clientInfo,
                    measurements: [...context.partnerClientSession.clientInfo.measurements, ...addedMeasurements],
                  },
                },
              });
            }
          },
        }),
        measurement_stats: funnelPage(MeasurementStats, { pageWidth: 720 }),
        thank_you: funnelPage(PageTemplates.TitleText({ title: t('thankYou.title'), nextLabel: t('done') }), {
          terminal: true,
          onExit: async () => {
            window.location.href = '/dk/partner';
          },
        }),
      },
    },
    routingRules: {
      checkup: {
        enter_code: ({ error }) => (error ? 'checkup/enter_code' : undefined),
      },
    },
    defaultLoader: {
      component: () => null,
      progressBar: true,
      minimumDurationSeconds: 0.5,
    },
    initialContext,
    defaultPageWidth: 480,
  });
