import { Flex, Group, Stack, Text, TextInput } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { FunctionComponent, useEffect, useRef, useState } from 'react';

import classes from '../themed-css/PinCodeInput.module.css';
export type OtpState = 'not_sent' | 'sent' | 'confirmed' | 'send_fail' | 'confirm_fail' | 'sending' | 'confirming';

interface IProps {
  size: number;
  onChange?: (pinCode?: string) => void;
  otpState?: OtpState;
}

export const PinCodeInput: FunctionComponent<IProps> = ({ size, onChange, otpState }) => {
  const steps = Array(size).fill(0);
  const [pinInput, setPinInput] = useState<string>('');
  const [pinHasFocus, setPinHasFocus] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement>();
  const { width } = useViewportSize();

  useEffect(() => {
    if (pinInput.length === size) {
      onChange?.(pinInput);
    } else {
      onChange?.();
    }
  }, [onChange, pinInput, size]);

  useEffect(() => {
    // if new otp has been requested we reset the pin code
    if (otpState === 'sent' || otpState === 'confirm_fail') {
      setPinInput('');
      ref?.current?.focus();
    }
  }, [otpState]);

  useEffect(() => {
    ref?.current?.focus();
  }, [ref]);

  const nextPinCodeIndex = !pinHasFocus ? -1 : pinInput.length || 0;

  return (
    <Stack gap={0}>
      <Group
        onClick={() => {
          ref.current?.focus();
        }}
        gap={width > 360 ? 'sm' : 'xs'}
        align="center"
        justify="center"
      >
        {steps.map((s, i) => {
          const stepValue = pinInput.charAt(i);
          return (
            <Flex
              key={`digit-${i}`}
              mih={width > 360 ? 64 : 48}
              miw={width > 360 ? 48 : 36}
              bg="neutral.0"
              justify="center"
              align="center"
              direction="row"
              wrap="wrap"
              classNames={classes}
              data-pin={nextPinCodeIndex === i}
              style={{
                borderWidth: nextPinCodeIndex === i ? 1 : 0,
              }}
            >
              {stepValue ? (
                <Text size={width > 360 ? '36px' : '30px'} fw={400} data-filled>
                  {stepValue}
                </Text>
              ) : (
                <Text size={width > 360 ? '36px' : '30px'} fw={400} data-empty>
                  0
                </Text>
              )}
            </Flex>
          );
        })}
      </Group>
      <TextInput
        ref={ref}
        data-testid={otpState === 'sent' ? 'pincode-input' : null}
        onChange={(e) => {
          const strValue = e.currentTarget.value.toString();
          setPinInput(strValue.length > size ? strValue.substring(0, size) : strValue);
        }}
        value={pinInput}
        maxLength={size}
        pattern="[0-9]*"
        inputMode="numeric"
        type="number"
        onBlur={() => {
          setPinHasFocus(false);
        }}
        onFocus={() => {
          setPinHasFocus(true);
        }}
        style={{ height: 0, width: 0, opacity: 0 }}
      />
    </Stack>
  );
};
