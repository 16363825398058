import { Title, TitleProps } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

interface IProps extends Omit<TitleProps, 'title' | 'size'> {
  title: ReactNode;
  size?: 'lg' | 'md' | 'sm';
}

export const StepTitle: FunctionComponent<IProps> = ({ title, size = 'sm', ...titleProps }) => {
  return (
    <Title ta="center" size={getSize(size)} lh={getLh(size)} fw={400} {...titleProps}>
      {title}
    </Title>
  );
};

const getSize = (size: 'lg' | 'md' | 'sm'): number => {
  switch (size) {
    case 'lg':
      return 36;
    case 'md':
      return 30;
    case 'sm':
      return 24;
  }
};

const getLh = (size: 'lg' | 'md' | 'sm'): string => {
  switch (size) {
    case 'lg':
      return '44px';
    case 'md':
      return '38px';
    case 'sm':
      return '32px';
  }
};
