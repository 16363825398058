import { TopBar } from '@shared/components/TopBar';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { FunctionComponent, useEffect, useState } from 'react';
import { t } from 'src/i18n';

import { createPartnerCheckupFunnelConfig } from './configs/partner-checkup/config';
import { usePartnerSession } from './util/helpers';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'checkup':
      return t('topCheckup');
  }
};

export const FunnelPartnerCheckup: FunctionComponent = () => {
  const { partnerCode, partnerInfo } = usePartnerSession();
  const [funnelConfig, setFunnelConfig] = useState<ReturnType<typeof createPartnerCheckupFunnelConfig>>();

  useEffect(() => {
    if (partnerCode && partnerInfo) {
      console.log('PARTNERINFO', partnerInfo);
      setFunnelConfig(
        createPartnerCheckupFunnelConfig({
          partnerCode,
          partnerInfo,
        }),
      );
    }
  }, [partnerCode, partnerInfo]);

  if (!funnelConfig) return null;
  return (
    <Funnel
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
      debug={debugEnabled()}
      name="partner-checkup"
      options={funnelConfig}
    />
  );
};
