import api from '@shared/api';
import {
  CountryIso3166,
  EligibilityType,
  FinancialPrice,
  LocaleIso6391,
  PartnerInfo,
  UserGetQuery,
} from '@shared/gql/sdk';
import { getUrlParams } from '@shared/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

import { t } from '../../i18n';
import IFunnelContext, { ProductSelection } from '../FunnelContext';

export const getPartnerCodeFromUrl = () => {
  const urlParams = getUrlParams();
  return urlParams.get('partnerCode');
};

export const usePartnerSession = (clearSession?: boolean) => {
  const [invalidCode, setInvalidCode] = useState(false);
  const [partnerCode, setPartnerCode] = useLocalStorage<string>('partnerCode', undefined);
  const [partnerInfo, setPartnerInfo] = useState<PartnerInfo>();

  useEffect(() => {
    const codeFromUrl = getPartnerCodeFromUrl();

    if (codeFromUrl) {
      setPartnerCode(codeFromUrl);
    }
  }, [setPartnerCode]);

  useEffect(() => clearSession && sessionStorage.clear(), [clearSession]);
  const lookupPartner = useCallback(async (code: string) => {
    setInvalidCode(false);
    const partner = await api.PartnerInfo({ partnerCode: code });

    if (partner.PartnerInfo) {
      setPartnerInfo(partner.PartnerInfo);
    } else {
      setInvalidCode(true);
    }
  }, []);

  useEffect(() => {
    if (partnerCode) {
      lookupPartner(partnerCode);
    }
  }, [partnerCode, lookupPartner]);

  return { partnerCode, setPartnerCode, partnerInfo, invalidCode } as const;
};

export const anyTicked = (form: { [key: string]: boolean }) => {
  return Object.keys(form).some((key) => form[key as keyof typeof form]);
};

export const onlyTickedOneOrMore = <T extends { [key: string]: boolean }>(form: T, keys: (keyof T)[]) => {
  return Object.keys(form).every((k) => {
    if (form[k] === true) {
      return keys.includes(k);
    }
    return true;
  });
};

export const getSessionLock = (lockName: string) => {
  if (sessionStorage.getItem(lockName)) return false;

  sessionStorage.setItem(lockName, 'true');
  return true;
};

export const initializeFromURL = (): Partial<IFunnelContext> => {
  const params = new URLSearchParams(window.location.search);
  const promoCode = params.get('promoCode');
  const embedded = params.get('app') === 'true';
  return {
    prefilledDiscountCode: promoCode,
    embedded,
  };
};

const getDiscountCode = (user: UserGetQuery['User']) => {
  if (!user) return;

  try {
    const parsedMetaData = JSON.parse(user.metaData);
    return parsedMetaData.emblaShareSignupValue;
  } catch (e) {
    return;
  }
};

export const initializeSession = async (
  token: string,
): Promise<{
  context: Partial<IFunnelContext>;
  user: UserGetQuery['User'];
}> => {
  sessionStorage.setItem('token', token);
  const { User } = await api.UserGet();
  const { phoneNumber, phoneVerified, id } = User;

  return {
    context: {
      prefilledDiscountCode: getDiscountCode(User),
      userSession: {
        firstName: User.firstName,
        phoneConfirmed: phoneVerified,
        phoneNumber: phoneNumber,
        token,
        userId: id,
        email: User.email,
      },
    },
    user: User,
  };
};

export const getPriceInfoByProductSelection = (productSelection: ProductSelection, priceInfos: FinancialPrice[]) => {
  switch (productSelection) {
    case ProductSelection.CoachingOnly:
      return priceInfos.find((pi) => pi.product.eligibilityType === EligibilityType.Coaching);
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      return priceInfos.find((pi) => pi.product.eligibilityType === EligibilityType.CoachingAndMedication);
  }
};

const getDefaultProductTitle = (product: ProductSelection) => {
  switch (product) {
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      return t('medicalWeightLossProgram.title');
    case ProductSelection.CoachingOnly:
      return t('coachingOnly.title');
    default:
      return product.toString();
  }
};

export const getDefaultPriceInfo = (selectedProduct: ProductSelection, country: CountryIso3166) => {
  const defaultName = getDefaultProductTitle(selectedProduct);
  // FALLBACK PRICE INFO - STATIC - SHOULD IDEALLY NEVER GET CALLED
  const currency = country === CountryIso3166.Gb ? 'gbp' : 'dkk';
  let amount: number;

  switch (selectedProduct) {
    case ProductSelection.MedicationAndCoaching:
    case ProductSelection.CoachingAndMaybeMedication:
      amount = country === CountryIso3166.Gb ? 29900 : 99500;
      break;
    case ProductSelection.CoachingOnly:
    case ProductSelection.MedicationTaperingOff:
      amount = country === CountryIso3166.Gb ? 9900 : 49500;
      break;
  }

  return {
    name: defaultName,
    currency,
    amount,
    campaignText: undefined as string,
    campaignAmount: undefined as number,
    campaignDiscount: undefined as {
      duration: string;
      durationInMonths: number;
    },
  };
};

export const formatPrice = (amount: number, locale: LocaleIso6391, currency: string) => {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });

  return formatCurrency.format(amount / 100);
};
