import { EmblaTheme } from '@joinembla/theme';
import { Checkbox, LoadingOverlay, Stack, Tooltip } from '@mantine/core';
import { NextButton } from '@shared/components/buttons/NextButton';
import { useTheme } from '@shared/theme';
import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, loadStripe } from '@stripe/stripe-js';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const getStripeElementsOptions = (currentTheme: EmblaTheme) => {
  const primaryFont = currentTheme.assets.primaryFont;

  const fontFamily: string = currentTheme.assets.primaryFont.name;
  const fontUrl = currentTheme.assets.fonts.find((f) => f.name === primaryFont.weights.regular.normal).source;

  return {
    fonts: [
      {
        family: fontFamily,
        src: `url(${fontUrl})`,
        weight: '500',
      },
    ],
    appearance: {
      variables: {
        fontFamily: fontFamily,
        borderRadius: '1rem',
        focusOutline: `0.0625rem solid `,
        focusBoxShadow: 'none',
        spacingGridRow: '16px',
        fontSizeBase: '16px',
        spacingUnit: '5',
      },
    },
  };
};

const Subscribe: FunctionComponent<{
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
  preSubmit: () => boolean;
  submitLabel: string;
  requiresConfirmation: boolean;
}> = ({ onSuccess, onError, preSubmit, submitLabel, requiresConfirmation }) => {
  const { t } = useTranslation();
  const elements = useElements();
  const stripe = useStripe();
  const [readyToPay, setReadyToPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmation, setConfirmation] = useState(!requiresConfirmation);

  const subscribe = async () => {
    try {
      setLoading(true);
      const res = preSubmit?.() || true;

      if (!res) {
        setLoading(false);
        return;
      }
      await elements.submit();
      const result = await stripe.confirmPayment({
        redirect: 'if_required',
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
      });
      if (result.error) {
        throw result.error.message;
      }
      onSuccess();
    } catch (error) {
      setLoading(false);
      onError(error.toString());
    }
  };

  return (
    <Stack m={4}>
      <LoadingOverlay
        visible={loading}
        overlayProps={{
          radius: 'sm',
          blur: 2,
          backgroundOpacity: 0.1,
        }}
      />
      <PaymentElement
        onChange={(e) => {
          setReadyToPay(e.complete);
          setLoading(false);
        }}
        onReady={() => {
          setLoading(false);
        }}
      />
      {requiresConfirmation ? (
        <Checkbox
          className="stripe-checkbox"
          radius="5"
          label={<strong>{t('checkoutConfirmationDescription')}</strong>}
          checked={confirmation}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => {
            setConfirmation(e.target.checked);
          }}
        />
      ) : null}

      <Tooltip label="foo">
        <NextButton onClick={subscribe} label={submitLabel} disabled={!confirmation || !readyToPay} />
      </Tooltip>
    </Stack>
  );
};

const SubscriptionFlow: FunctionComponent<{
  clientSecret: string;
  publicKey: string;
  submitLabel: string;
  requiresConfirmation: boolean;
  preSubmit?: () => boolean;
  onSuccess: () => void;
  onError: (errorMessage: string) => void;
}> = ({ onSuccess, onError, preSubmit, requiresConfirmation, clientSecret, publicKey, submitLabel }) => {
  const { theme } = useTheme();
  const [stripePromise, setStripePromise] = useState<Promise<Stripe>>();
  const initializationStarted = useRef(false);

  useEffect(() => {
    if (initializationStarted.current) return;
    initializationStarted.current = true;

    const initialize = async () => {
      try {
        setStripePromise(loadStripe(publicKey));
      } catch (error) {
        if (error instanceof Error) {
          onError(error.message);
        } else {
          onError(error.toString());
        }
      }
    };

    initialize();
  }, [initializationStarted, publicKey]);

  if (!stripePromise) {
    return null;
  }

  return (
    <Elements stripe={stripePromise} options={{ ...getStripeElementsOptions(theme.other.theme), clientSecret }}>
      <Subscribe
        onSuccess={onSuccess}
        onError={onError}
        preSubmit={preSubmit}
        submitLabel={submitLabel}
        requiresConfirmation={requiresConfirmation}
      />
    </Elements>
  );
};

export default SubscriptionFlow;
