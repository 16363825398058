import StandardFooter from '@shared/components/StandardFooter';
import { TopBar } from '@shared/components/TopBar';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { useRef } from 'react';
import { env } from 'src/env';
import { t } from 'src/i18n';

import { createFunnelV3DKConfig } from './configs/dk/config';
import { IFunnelSession } from './FunnelContext';
import { hydrateDataFromSession } from './util/hydration';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'program':
      return t('topProgram');
    case 'eligibility':
      return t('topEligibility');
    case 'booking':
      return t('topBooking');
  }
};

const getConfig = (session: IFunnelSession) => {
  const isPartnerRef = session.context.prefilledDiscountCode === env.partnerCoupon;

  const config = createFunnelV3DKConfig({ ...session.context, noUserDiscount: isPartnerRef });
  config.initialData = hydrateDataFromSession(config.config, session);
  return config;
};

const FunnelV3DKComponent: React.FunctionComponent<{
  session: IFunnelSession;
}> = ({ session }) => {
  const funnelConfig = useRef(getConfig(session)).current;

  return (
    <Funnel
      debug={debugEnabled()}
      name="v3-dk"
      options={funnelConfig}
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
      footerDisplay={() => (!session.context.embedded ? <StandardFooter /> : null)}
    />
  );
};

export default FunnelV3DKComponent;
