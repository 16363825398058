import { Box, List, MantineSpacing, Text } from '@mantine/core';
import { FunctionComponent, ReactNode } from 'react';

interface IProps {
  items: {
    icon: (props: { width?: number; color?: string }) => ReactNode;
    label: string | ReactNode;
  }[];
  color?: string;
  weight?: number;
  spacing?: MantineSpacing;
}
export const IconList: FunctionComponent<IProps> = ({ items, color, weight = 600, spacing }) => {
  return (
    <List spacing={8}>
      {items.map((item, idx) => (
        <List.Item key={idx + String(item.label)} icon={<Box mt={2}>{item.icon?.({ width: 16, color })}</Box>}>
          <Text ml={spacing && spacing} fw={weight}>
            {item.label}
          </Text>
        </List.Item>
      ))}
    </List>
  );
};
