import { UnitSystem } from '@shared/gql/sdk';
import { useCallback } from 'react';

const LBS_IN_KG = 2.20462;
const LB_IN_ST = 14;

const CM_IN_FT = 30.48;
const IN_IN_FT = 12;
const CM_PER_IN = 2.54;

type Kilograms = number;
type StonesAndPounds = { stones: number; pounds: number };

export type Weight = Kilograms | StonesAndPounds;

const weightIsKilograms = (weight: Weight): weight is Kilograms => {
  return typeof weight === 'number';
};
export const feetInchesToCm = (feet = 0, inches = 0) => {
  return (feet + inches / IN_IN_FT) * CM_IN_FT;
};

export const stonesPoundsToKg = (stones = 0, pounds = 0) => {
  return (stones * 14 + pounds) / LBS_IN_KG;
};

export const kgToStonesPounds = (kg: number) => {
  if (!kg) return { stones: undefined, pounds: undefined };
  const pounds = kg * LBS_IN_KG;
  const stones = Math.floor(pounds / LB_IN_ST);
  const remainingPounds = Math.round(pounds - stones * LB_IN_ST);

  return { stones, pounds: remainingPounds };
};

export const cmToFeetInches = (cm: number) => {
  if (!cm) return { feet: undefined, inches: undefined };
  const inches = cm / CM_PER_IN;
  const feet = Math.floor(inches / IN_IN_FT);
  const remainingInches = Math.round(inches - feet * IN_IN_FT);
  return { feet, inches: remainingInches };
};

export const formatWeight = (weight: Weight, round = true) => {
  if (weightIsKilograms(weight)) {
    return `${round ? Math.round(weight) : weight} kg`;
  } else {
    return `${round ? Math.round(weight.stones) : weight.stones}st ${
      round ? Math.round(weight.pounds) : weight.pounds
    }lb`;
  }
};

export const useUnitConversion = (unitSystem: UnitSystem = UnitSystem.Metric) => {
  const convert = useCallback(
    (weightKg: number): Weight => {
      switch (unitSystem) {
        case 'metric':
          return weightKg;
        case 'imperial':
          return kgToStonesPounds(weightKg || 0);
      }
    },
    [unitSystem],
  );

  const format = useCallback(
    (weightKg: number, round = true) => {
      return formatWeight(convert(weightKg || 0), round);
    },
    [convert],
  );

  return {
    format,
    convertToUnitSystem: convert,
  };
};
