import { Card, Group, MantineStyleProp, Stack, Text } from '@mantine/core';
import { useTheme } from '@shared/theme';
import { IconCashBanknoteOff, IconClockCancel, IconTruckDelivery } from '@tabler/icons-react';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductSelection } from 'src/v3/FunnelContext';

interface Props {
  selectedProduct: ProductSelection;
}

export const Benefits: React.FC<Props> = ({ selectedProduct }) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const showShipping = selectedProduct === ProductSelection.MedicationAndCoaching;

  return (
    <Group align="flex-start">
      <Benefit
        style={{ flex: 1 }}
        text={t('benefit.freeCancellation')}
        icon={<IconClockCancel size={20} color={theme.other.theme.screen.primary} />}
      />
      <Benefit
        style={{ flex: 1 }}
        text={t('benefit.fullRefundIfIneligible')}
        icon={<IconCashBanknoteOff size={20} color={theme.other.theme.screen.primary} />}
      />
      {showShipping && (
        <Benefit
          style={{ flex: 1 }}
          text={t('benefit.trackedDelivery')}
          icon={<IconTruckDelivery size={20} color={theme.other.theme.screen.primary} />}
        />
      )}
    </Group>
  );
};

const Benefit: React.FC<{
  text: string;
  icon: ReactNode;
  style?: MantineStyleProp;
}> = ({ text, icon, style = {} }) => {
  return (
    <Card style={style} data-background-light={true}>
      <Stack gap="sm" align="center" justify="cente">
        {icon}
        <Text fw={600} size="sm" style={{ textAlign: 'center' }}>
          {text}
        </Text>
      </Stack>
    </Card>
  );
};
