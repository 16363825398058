import { Group, Stack, Text, Tooltip } from '@mantine/core';
import { Receipt } from '@shared/components/Receipt';
import { EligibilityType, FinancialPrice } from '@shared/gql/sdk';
import { formatAmount, formatDiscountAmount, formatInterval, getProductNameByProduct } from '@shared/helpers';
import { IconInfoCircle } from '@tabler/icons-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  price: FinancialPrice;
}

type ReceiptProps = React.ComponentProps<typeof Receipt>;

export const MembershipSummary: React.FC<Props> = ({ price }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={0}>
      <Text py="xs" fw="bold">
        {t('membershipSummary')}
      </Text>
      <Receipt items={getReceiptItems(price, t)} total={getReceiptTotal(price)} />
    </Stack>
  );
};

const getReceiptTotal = (price: FinancialPrice) => {
  if (!price) return '';

  const hasCoupon = price.campaignAmount != null;

  return formatAmount(hasCoupon ? price.campaignAmount : price.amount, price.currency);
};

const getReceiptItems = (price: FinancialPrice, t: (key: string, vars?: Record<string, unknown>) => string) => {
  if (!price) return [];

  const hasCoupon = price.campaignAmount != null;

  const items: ReceiptProps['items'] = [
    {
      label: `${getProductNameByProduct(price.product)} ${t('membership')}`,
      value: <Text td={hasCoupon ? 'line-through' : null}>{formatAmount(price.amount, price.currency)}</Text>,
    },
    {
      label: (
        <Group gap="xs">
          <Text>{t('interval')}</Text>
          <Tooltip label={t('membershipInterval.tooltip')}>
            <IconInfoCircle size={12} style={{ marginBottom: 16 }} />
          </Tooltip>
        </Group>
      ),
      value: formatInterval(price),
    },
    {
      label: t('fullMobileAppAccess'),
      value: (
        <Text size="md" fw={600} data-discount>
          {t('free')}
        </Text>
      ),
    },
  ];

  if (price.product.eligibilityType === EligibilityType.CoachingAndMedication) {
    items.push({
      label: t('shipping'),
      value: (
        <Text size="md" fw={600} data-discount>
          {t('free')}
        </Text>
      ),
    });
  }

  if (hasCoupon) {
    items.push(
      ...[
        {
          label: price.campaignText ? t('discountWithText', { text: price.campaignText }) : t('discount'),
          value: (
            <Text fw="bold" data-discount>
              {price.campaignDiscount?.percentage
                ? ` - ${price.campaignDiscount?.percentage}%`
                : price.campaignDiscount?.amount
                  ? formatDiscountAmount(price.campaignDiscount?.amount, price.campaignDiscount?.currency)
                  : undefined}
            </Text>
          ),
        },
      ],
    );
  }

  return items;
};
