import { ReactComponent as CheckIcon } from '@assets/icons/fi-rr-check.svg';
import { Checkbox, CheckboxProps } from '@mantine/core';
import { FunctionComponent } from 'react';

export const EmblaCheckbox: FunctionComponent<CheckboxProps> = (props) => {
  return (
    <Checkbox
      {...props}
      icon={CheckIcon}
      styles={{
        error: {
          color: 'var(--mantine-color-error-3)',
          fontSize: 'var(--mantine-color-font-size-sm)',
        },
      }}
    />
  );
};
