import { resolveIntl } from './resolveIntl';

const fallbackLinks: Record<string, string> = {
  gb: 'https://www.joinembla.com/uk/mobile-app',
  dk: 'https://www.joinembla.com/dk/mobil-app',
};

export const openInApp = (href: string) => {
  const country = resolveIntl().country.toLowerCase();
  const path = href.split('/app/')[1];

  setTimeout(() => {
    // if scheme does not have a registered handler fallback to app page that resolves into app store or website
    window.location.href = fallbackLinks[country];
  }, 50);

  window.location.href = `embla://joinembla.com/${path ?? ''}`;
};
