import StandardFooter from '@shared/components/StandardFooter';
import { TopBar } from '@shared/components/TopBar';
import { useFeatureIsOn } from '@shared/FeatureToggleContext';
import { Funnel } from '@shared/funnel-engine';
import { debugEnabled } from '@shared/helpers';
import { useEffect, useRef } from 'react';
import { t } from 'src/i18n';

import { createV3FunnelConfig as createV3FunnelCallbackConfig } from './configs/uk-checkout-or-book/config-callback-request';
import { IFunnelSession } from './FunnelContext';
import { hydrateDataFromSession } from './util/hydration';
import './FunnelUKWithCheckoutOrBook.css';

const mapSectionLabel = (label: string) => {
  switch (label) {
    case 'details':
      return t('topDetails');
    case 'program':
      return t('topProgram');
    case 'eligibility':
      return t('topEligibility');
    case 'checkout':
      return t('topCheckout');
  }
};

const getCallbackRequestConfig = (session: IFunnelSession, skipIntro: boolean) => {
  const config = createV3FunnelCallbackConfig(session.context, skipIntro);
  config.initialData = hydrateDataFromSession(config.config, session);
  return config;
};

const FunnelUKWithCheckoutOrBookComponent: React.FunctionComponent<{
  session: IFunnelSession;
}> = ({ session }) => {
  const { context } = session || {};
  const noIntro = useFeatureIsOn('uk-funnel-no-intro');
  const funnelCallbackConfig = useRef(getCallbackRequestConfig(session, noIntro));

  useEffect(() => {
    funnelCallbackConfig.current = getCallbackRequestConfig(session, noIntro);
  }, [noIntro, session]);

  return (
    <Funnel
      name="v3-uk-with-checkout-or-book"
      debug={debugEnabled()}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      options={funnelCallbackConfig.current}
      sectionDisplay={(sections) => (
        <TopBar
          sections={sections.map((s) => ({
            ...s,
            label: mapSectionLabel(s.label as string),
          }))}
        />
      )}
      footerDisplay={() => (!context?.embedded ? <StandardFooter /> : null)}
    />
  );
};

export default FunnelUKWithCheckoutOrBookComponent;
