import { useEffect, useState } from 'react';

export const useIndexStepper = (
  steps: number[],
  //roundTripTimeInMillies: number
) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [currentStepTime, setCurrentStepTime] = useState<number>(steps[0]);
  const arraySize = steps.length;

  useEffect(() => {
    let nextIndex = 0;
    if (currentIndex < arraySize - 1) {
      nextIndex = currentIndex + 1;
    }
    if (nextIndex != currentIndex) {
      setTimeout(() => {
        setCurrentIndex(nextIndex);
        setCurrentStepTime(steps[nextIndex]);
      }, currentStepTime);
    }
  }, [arraySize, currentIndex, currentStepTime, steps]);

  return {
    currentIndex,
    currentStepTime,
  };
};
