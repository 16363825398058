import projectionGraphCoachingAndMedication from '@assets/weight-loss-prediction-v3.svg';
import { Card, Group, Image, MantineSize, Stack, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { StepTitle } from '@shared/components/StepTitle';
import { UnitSystem } from '@shared/gql/sdk';
import { useUnitConversion } from '@shared/unitConversion';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

import { useWeightPrediction } from './useWeightPrediction';

export const CoachingAndMedication: FunctionComponent<{
  weight: number;
  unitSystem: UnitSystem;
}> = ({ weight, unitSystem }) => {
  const { targetWeight } = useWeightPrediction(weight);
  const { format } = useUnitConversion(unitSystem);
  const { t } = useTranslation();

  return (
    <Stack align="center" gap="lg">
      <StepTitle
        size="sm"
        title={
          <>
            {t('withTheEmblaMethodYouCouldReach')}
            <Text data-secondary fw={700} style={{ fontSize: 24, display: 'inline' }}>
              {' '}
              {format(targetWeight)}{' '}
            </Text>
            {t('in6Months')}.*
          </>
        }
      />
      <Stack maw={460} w={'100%'} gap={0}>
        <WeightProjectionGraphCoachingAndMedication unitSystem={unitSystem} weight={weight} />
        <Text fw={300} fs={'italic'} size={'sm'} ta="center">
          {t('aboveIsSimplifiedProjection')}
        </Text>
      </Stack>
    </Stack>
  );
};

const WeightProjectionGraphCoachingAndMedication: FunctionComponent<{
  unitSystem: UnitSystem;
  weight: number;
}> = ({ unitSystem, weight }) => {
  const { targetWeight } = useWeightPrediction(weight);
  const { format } = useUnitConversion(unitSystem);
  const { ref: markerRef, width: markerWidth } = useElementSize<HTMLDivElement>();

  const graphBg = projectionGraphCoachingAndMedication;

  return (
    <Group w="100%" wrap="nowrap" align="stretch" gap={'sm'}>
      <div
        style={{
          display: 'flex',
          paddingTop: 10,
          paddingBottom: 10,
          position: 'relative',
          flex: 1,
        }}
      >
        <Image w="100%" style={{ flex: 1 }} src={graphBg} data-img-filter={true} />
        {targetWeight && (
          <div
            ref={markerRef}
            style={{
              position: 'absolute',
              left: `calc(73% - ${Math.round(markerWidth / 2)}px)`,
              bottom: '48%',
            }}
          >
            <WeightBadge size="xl" content={format(targetWeight)} />
          </div>
        )}
      </div>
    </Group>
  );
};

const WeightBadge: FunctionComponent<{
  content: string;
  size: MantineSize;
}> = ({ content, size }) => {
  return (
    <Card radius="lg" fw={'bold'} data-background-light py={0} px={12} shadow="md">
      <Text fw={600} size={size} data-secondary>
        {content}
      </Text>
    </Card>
  );
};
