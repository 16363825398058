import dayjs from 'dayjs';
import { useMemo } from 'react';

export const useWeightPrediction = (weight: number) => {
  const targetDate = useMemo(() => dayjs().add(6, 'months'), []);
  const targetWeight = weight ? Math.round(weight * 0.869) : undefined;
  const targetWeightLoss = weight ? Math.round(weight - targetWeight) : undefined;

  return {
    currentWeight: weight,
    targetWeight,
    targetDate,
    targetWeightLoss,
  };
};
