import { Stack, Text } from '@mantine/core';
import { useViewportSize } from '@mantine/hooks';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { RadioCard } from '@shared/components/RadioCard';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../../FunnelContext';

type WeightlossApproachResult = 'generic' | 'personalized';

export const WeightlossApproach: FunnelPageComponent<
  {
    value?: WeightlossApproachResult;
  },
  IFunnelContext
> = ({ data: { value: initialValue }, funnelApi }) => {
  const { t } = useTranslation();
  const { width } = useViewportSize();
  const isMobile = width < 500;
  const [value, setValue] = useState<WeightlossApproachResult>(initialValue);

  return (
    <Stack style={isMobile ? { flex: 1, alignSelf: 'center' } : { height: 600 }}>
      <Stack justify="center" style={{ flex: 1 }}>
        <Text ta="center" size={'xl'}>
          <Trans i18nKey={'whichApproachDoYouThink'} />
        </Text>
        <Stack gap="sm">
          <RadioCard
            key={'generic'}
            label={t('aGenericOneSizeFitsAll')}
            checked={value === 'generic'}
            select={() => {
              setValue('generic');
            }}
          />
          <RadioCard
            key={'personalized'}
            label={t('aHighlyPersonalizedApproach')}
            checked={value === 'personalized'}
            select={() => {
              setValue('personalized');
            }}
          />
        </Stack>
      </Stack>

      <NextButton
        disabled={!value}
        onClick={() =>
          funnelApi.next({
            value,
          })
        }
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
