import './index.css';
import './App.css';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import 'dayjs/locale/da';
import 'dayjs/locale/en-gb';

import * as Sentry from '@sentry/react';
import { DefaultContexts } from '@shared/DefaultContexts.tsx';
import { LocaleIso6391 } from '@shared/gql/sdk';
import { getFirstMatchingValue, resolveIntl } from '@shared/helpers.ts';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import ReactDOM from 'react-dom/client';

import { App } from './App.tsx';
import i18n from './i18n';
import { openInApp } from './open-in-app.ts';
import FunnelDK from './v3/FunnelDK.tsx';
import { FunnelPartnerCheckup } from './v3/FunnelPartnerCheckup.tsx';
import { FunnelPartnerSignup } from './v3/FunnelPartnerSignup.tsx';
import FunnelUKWithCheckoutOrBookComponent from './v3/FunnelUKWithCheckoutOrBook.tsx';
import { PartnerEntry } from './v3/PartnerEntry.tsx';

const { locale } = resolveIntl();
dayjs.extend(localizedFormat);

if (locale === LocaleIso6391.Da) {
  dayjs.locale('da');
} else if (locale === LocaleIso6391.En) {
  dayjs.locale('en-gb');
}

Sentry.init({
  dsn: 'https://bed0fb5a2f4c45209f1c4bffbac6c0f8@o4503966019616768.ingest.sentry.io/4505356968984576',
  integrations: [
    new Sentry.BrowserTracing({
      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ["localhost", /^https:\/\/web\.joinembla\.com/],
      tracePropagationTargets: [/dev-app\.joinembla\com/, /app\.joinembla\com/],
    }),
    new Sentry.Replay({
      networkDetailAllowUrls: ['https://dev-app.joinembla.com/graphql', 'https://app.joinembla.com/graphql'],
    }),
  ],
  enabled: import.meta.env['VITE_SENTRY_ENABLED'] === 'true',
  environment: import.meta.env['VITE_SENTRY_ENVIRONMENT'],
  // performance Monitoring
  tracesSampleRate: parseFloat(import.meta.env['VITE_SENTRY_SAMPLE_RATE']) || 0,
  // session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const { pathname, href } = window.location;
const isAppLink = pathname.slice(0, 4) === '/app';
const isAppLinkWithCountryCode = pathname.includes('/dk/app') || pathname.includes('/uk/app');

if (isAppLink || isAppLinkWithCountryCode) {
  openInApp(href);
} else {
  // Routing table - will use FIRST MATCH meaning that order is important
  // Put more specific first eg. /dk/partner/signup BEFORE /dk/partner
  const component = getFirstMatchingValue(pathname, {
    '/uk/signup': <App funnelComponent={FunnelUKWithCheckoutOrBookComponent} />,
    '/dk/signup': <App funnelComponent={FunnelDK} />,
    '/dk/partner/signup': <FunnelPartnerSignup />,
    '/dk/partner/checkup': <FunnelPartnerCheckup />,
    '/dk/partner': <PartnerEntry />,
  });

  if (component) {
    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
      <DefaultContexts i18n={i18n}>{component}</DefaultContexts>,
    );
  } else {
    window.location.pathname = '/uk/signup';
  }
}
