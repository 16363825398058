import { ReactComponent as IconComment } from '@assets/icons/fi-rr-comment.svg';
import { ReactComponent as IconPhoneCall } from '@assets/icons/fi-rr-phone-call.svg';
import { Anchor, Card, Stack, Text } from '@mantine/core';
import { IconList } from '@shared/components/IconList';
import { useTheme } from '@shared/theme';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const FaqCard: FunctionComponent = () => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  return (
    <Card>
      <Stack>
        <Text size="md">{t('haveAnyQuestions')}</Text>

        <IconList
          color={theme.other.theme.screen.secondary}
          items={[
            {
              icon: IconComment,
              label: (
                <Anchor data-secondary fw={600} href="https://www.joinembla.com/uk/faq" target="_blank">
                  {t('chatWithUs')}
                </Anchor>
              ),
            },
            {
              icon: IconPhoneCall,
              label: (
                <Anchor data-secondary fw={600} href="tel:02033147918">
                  {t('callUs', { tel: '020 3314 7918' })}
                </Anchor>
              ),
            },
          ]}
        />
      </Stack>
    </Card>
  );
};
