import { Box, Group, Text, TextProps } from '@mantine/core';
import { FunctionComponent } from 'react';

interface IProps extends TextProps {
  label: string;
}
export const IndicatorWithLabel: FunctionComponent<IProps> = ({ label, ...textProps }) => {
  return (
    <Group wrap="nowrap" gap="sm" align="baseline">
      <Box w={10} h={10} style={{ borderRadius: 20 }}></Box>
      <Text p={0} m={0} {...textProps}>
        {label}
      </Text>
    </Group>
  );
};
