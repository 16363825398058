import { Group, Radio, Stack, Text } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { Disclaimer } from '@shared/components/Disclaimer';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const CurrentGLP1Medication: FunnelPageComponent<
  {
    value?: boolean;
    signupToWaitlist?: boolean;
  },
  IFunnelContext
> = ({ data: { value }, context: { features }, funnelApi }) => {
  const { t } = useTranslation();

  const [currentlyOnGLP1Medication, setCurrentlyOnGLP1Medication] = useState(value);

  useEffect(() => {
    setCurrentlyOnGLP1Medication(value);
  }, [value]);

  return (
    <Stack>
      <StepTitle title={t('areYouCurrentlyTakingAnyGLP1Medication')} mb={'xs'} />
      <Group justify={'center'} mb={'md'}>
        <Text size={'md'}>{t('glp1Examples')}</Text>
      </Group>

      <Radio.Group
        onChange={(val) => {
          if (val != null) {
            const onMedication = val === 'yes';
            setCurrentlyOnGLP1Medication(onMedication);
          }
        }}
        value={currentlyOnGLP1Medication === true ? 'yes' : currentlyOnGLP1Medication === false ? 'no' : undefined}
      >
        <Stack gap="sm">
          <RadioCard
            value={'yes'}
            label={t('yes')}
            select={() => setCurrentlyOnGLP1Medication(true)}
            checked={currentlyOnGLP1Medication === true}
          />
          <RadioCard
            value={'no'}
            label={t('no')}
            select={() => setCurrentlyOnGLP1Medication(false)}
            checked={currentlyOnGLP1Medication === false}
          />
        </Stack>
      </Radio.Group>

      {currentlyOnGLP1Medication && features?.higherDoseWaitlist ? (
        <Disclaimer
          type={'error'}
          pillText={t('pleaseNote')}
          message={t('wereOfferOnlyLowestDoseOfMedication')}
          action={{
            label: t('joinWaitlist'),
            onClick: async () => {
              funnelApi.next({
                value: currentlyOnGLP1Medication,
                signupToWaitlist: true,
              });
            },
          }}
        />
      ) : null}
      <NextButton
        disabled={currentlyOnGLP1Medication == null}
        onClick={() =>
          funnelApi.next({
            value: currentlyOnGLP1Medication,
          })
        }
      />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
