import SlidePhone from '@assets/images/slide_phone.jpg';
import { Box, Button, Group, Image, Input, Stack, Text, Title } from '@mantine/core';
import { EmblaPageLayout } from '@shared/components/layout/PageLayout';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { IconArrowRight } from '@tabler/icons-react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IFunnelContext from 'src/v3/FunnelContext';

export enum EnterCodeErrors {
  InvalidCode = 'invalid-code',
}

export const EnterCode: FunnelPageComponent<{ code: string }, IFunnelContext, EnterCodeErrors> = ({
  funnelApi,
  context,
}) => {
  const { t } = useTranslation();
  const { partnerInfo } = context;
  const [userCode, setUserCode] = useState<string>();

  return (
    <EmblaPageLayout heroImageSrc={SlidePhone}>
      <Stack gap="lg">
        <Group wrap="nowrap">
          <Image src={partnerInfo.logoImageUrl} fit="contain" mah={150} />
          <Box flex={1} />
        </Group>
        <Stack gap="xs">
          <Title fw={400}>
            <Trans i18nKey="enterCode.title" components={{ strong: <Title style={{ display: 'inline' }} /> }} />
          </Title>
          <Text data-secondary-font>{t('enterCode.description')}</Text>
        </Stack>
        <Input.Wrapper
          label={t('memberId')}
          error={funnelApi.error === EnterCodeErrors.InvalidCode ? t('invalidCode') : undefined}
        >
          <Input
            autoFocus
            placeholder={t('memberId')}
            onChange={(e) => setUserCode(e.currentTarget.value)}
            maxLength={8}
          />
        </Input.Wrapper>
        <Button
          rightSection={<IconArrowRight />}
          disabled={userCode?.length !== 8}
          onClick={() => funnelApi.next({ code: userCode })}
        >
          {t('next')}
        </Button>
      </Stack>
    </EmblaPageLayout>
  );
};
