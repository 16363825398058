import { Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { CheckboxCard } from '@shared/components/CheckboxCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { MotivationAnswersInput } from '@shared/gql/sdk';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export type Motivation = keyof MotivationAnswersInput;

type MotivationsForm = Partial<Record<Motivation, boolean>>;

export const Motivations: FunnelPageComponent<Partial<MotivationsForm>, IFunnelContext> = ({
  data: motivations,
  funnelApi,
}) => {
  const { t } = useTranslation();
  const form = useForm<MotivationsForm>({
    initialValues: {
      IMPROVE_PHYSICAL_HEALTH: motivations?.IMPROVE_PHYSICAL_HEALTH || false,
      IMPROVE_MENTAL_HEALTH: motivations?.IMPROVE_MENTAL_HEALTH || false,
      FITNESS_AND_CONFIDENCE: motivations?.FITNESS_AND_CONFIDENCE || false,
      LOWER_RISK_OF_DISEASES: motivations?.LOWER_RISK_OF_DISEASES || false,
      MORE_ENERGY: motivations?.MORE_ENERGY || false,
      OTHER: motivations?.OTHER || false,
    },
  });
  const resetOther = () => {
    form.setFieldValue('OTHER', false);
  };
  return (
    <Stack>
      <StepTitle title={t('whatDoYouWantToAchieveByLosingWeight')} />
      <Stack gap="sm">
        <CheckboxCard
          label={t('screeningQuestion.improvePhysicalHealth')}
          {...form.getInputProps('IMPROVE_PHYSICAL_HEALTH', {
            type: 'checkbox',
          })}
          onChange={(e) => {
            resetOther();
            form
              .getInputProps('IMPROVE_PHYSICAL_HEALTH', {
                type: 'checkbox',
              })
              .onChange(e);
          }}
        />

        <CheckboxCard
          label={t('screeningQuestion.improveMentalHealth')}
          {...form.getInputProps('IMPROVE_MENTAL_HEALTH', {
            type: 'checkbox',
          })}
          onChange={(e) => {
            resetOther();
            form
              .getInputProps('IMPROVE_MENTAL_HEALTH', {
                type: 'checkbox',
              })
              .onChange(e);
          }}
        />
        <CheckboxCard
          label={t('screeningQuestion.fitnessAndConfidence')}
          {...form.getInputProps('FITNESS_AND_CONFIDENCE', {
            type: 'checkbox',
          })}
          onChange={(e) => {
            resetOther();
            form
              .getInputProps('FITNESS_AND_CONFIDENCE', {
                type: 'checkbox',
              })
              .onChange(e);
          }}
        />
        <CheckboxCard
          label={t('screeningQuestion.lowerRiskOfDiseases')}
          {...form.getInputProps('LOWER_RISK_OF_DISEASES', {
            type: 'checkbox',
          })}
          onChange={(e) => {
            resetOther();
            form
              .getInputProps('LOWER_RISK_OF_DISEASES', {
                type: 'checkbox',
              })
              .onChange(e);
          }}
        />
        <CheckboxCard
          label={t('screeningQuestion.moreEnergy')}
          {...form.getInputProps('MORE_ENERGY', { type: 'checkbox' })}
          onChange={(e) => {
            resetOther();
            form
              .getInputProps('MORE_ENERGY', {
                type: 'checkbox',
              })
              .onChange(e);
          }}
        />

        <CheckboxCard
          label={t('screeningQuestion.other')}
          {...form.getInputProps('OTHER', {
            type: 'checkbox',
          })}
          onChange={(e) => {
            form.setValues({
              IMPROVE_PHYSICAL_HEALTH: false,
              IMPROVE_MENTAL_HEALTH: false,
              FITNESS_AND_CONFIDENCE: false,
              LOWER_RISK_OF_DISEASES: false,
              MORE_ENERGY: false,
            });
            form
              .getInputProps('OTHER', {
                type: 'checkbox',
              })
              .onChange(e);
          }}
        />
      </Stack>
      <Stack my={'md'}>
        <NextButton disabled={!Object.values(form.values).some(Boolean)} onClick={() => funnelApi.next(form.values)} />
        {funnelApi.state?.hasPreviousPage && <BackButton onClick={() => funnelApi.back()} />}
      </Stack>
    </Stack>
  );
};
