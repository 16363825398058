import { ReactComponent as IconArrowRight } from '@assets/icons/fi-rr-arrow-right.svg';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { GrowingButton } from './GrowingButton';

interface IProps {
  disabled?: boolean;
  loading?: boolean;
  onClick?: MouseEventHandler;
  type?: HTMLButtonElement['type'];
  label?: string;
  grow?: boolean;
}

export const NextButton: React.FunctionComponent<IProps> = ({ onClick, grow, type, disabled, loading, label }) => {
  const { t } = useTranslation();

  return (
    <GrowingButton
      forceGrow={grow}
      loading={loading}
      data-testid="next"
      disabled={disabled}
      buttonLabel={label || t('next')}
      rightSection={<IconArrowRight width={20} />}
      type={type}
      onClick={onClick}
    />
  );
};
