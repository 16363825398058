import imgEmma from '@assets/images/employees/emma.png';
import { EmployeeData, EmployeeType } from '@shared/types';

import { t } from '../../i18n';

export const Emma = (): EmployeeData => ({
  imageSrc: imgEmma,
  name: 'Emma Brix',
  title: t('coach'),
  quotes: [{ quote: t('qGreatYouQualify'), time: 3000 }],
});

export const getEmployeeByType = (type: EmployeeType) => {
  switch (type) {
    case 'COACH':
      return Emma();
  }
};

export const getLoadingTimeMsByType = (type: EmployeeType) =>
  getEmployeeByType(type)?.quotes.reduce((prev, cur) => prev + cur.time, 0);
