import { Radio, Stack } from '@mantine/core';
import { BackButton } from '@shared/components/buttons/BackButton';
import { NextButton } from '@shared/components/buttons/NextButton';
import { RadioCard } from '@shared/components/RadioCard';
import { StepTitle } from '@shared/components/StepTitle';
import { FunnelPageComponent } from '@shared/funnel-engine';
import { SexAssignedAtBirth } from '@shared/gql/sdk';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IFunnelContext from '../FunnelContext';

export const SexAssigned: FunnelPageComponent<{ value?: SexAssignedAtBirth }, IFunnelContext> = ({
  data: { value: value },
  funnelApi,
}) => {
  const { t } = useTranslation();
  const [sexAssignedAtBirth, setSexAssignedAtBirth] = useState<SexAssignedAtBirth>(value);

  return (
    <Stack gap={'md'}>
      <StepTitle title={t('whatIsYourSexAssignedAtBirth')} />

      <Radio.Group value={sexAssignedAtBirth}>
        <Stack gap="sm">
          <RadioCard
            checked={sexAssignedAtBirth === SexAssignedAtBirth.Male}
            value={SexAssignedAtBirth.Male}
            label={t('male')}
            select={() => setSexAssignedAtBirth(SexAssignedAtBirth.Male)}
          />
          <RadioCard
            checked={sexAssignedAtBirth === SexAssignedAtBirth.Female}
            value={SexAssignedAtBirth.Female}
            label={t('female')}
            select={() => setSexAssignedAtBirth(SexAssignedAtBirth.Female)}
          />
          <RadioCard
            checked={sexAssignedAtBirth === SexAssignedAtBirth.PreferNotToSay}
            value={SexAssignedAtBirth.PreferNotToSay}
            label={t('preferNotToSay')}
            select={() => setSexAssignedAtBirth(SexAssignedAtBirth.PreferNotToSay)}
          />
        </Stack>
      </Radio.Group>

      <NextButton disabled={!sexAssignedAtBirth} onClick={() => funnelApi.next({ value: sexAssignedAtBirth })} />
      <BackButton onClick={() => funnelApi.back()} />
    </Stack>
  );
};
